import { Component, OnInit } from '@angular/core';
import { CountryService } from '../../core/country.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslatePipe } from '../../translate';
import { SponsorService } from '../../core/sponsor.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-enroll',
  templateUrl: './enroll.component.html',
  styleUrls: ['./enroll.component.css']
})
export class EnrollComponent implements OnInit {
  bitcoin: boolean;
  zone: string;
  spons: string;
  currency: string = 'USD';
  cs4000 = 'https://backoffice.travorium.com/Travorium/enroll_new4/signup_form.asp';
  pkgtext = '?pkg=';
  ProductIDtext = '&ProductID=';
  ProductIDtext2 = '&ProductID2=';
  SponsorIDtext = '&SponsorID=';
  ProductID_AStext = '&ProductID_AS=';
  ProductID_AStext2 = '&ProductID2_AS=';
  sponsor = '';
  isupgrade;
  nocountryselected;
  makeitfxonly;
  travelpack;
  nosilver;
  agreetopack;
  D;
  C;
  skipit;
  caText = true
  partner;
  istravelpack;
  fortravel;
  fortrader;
  fortraveltrader;
  stepone;
  doyouwantpack = false;
  steptwo;
  stepthree;

  selectpartner = true;
  silverlive = true;
  partnerFees = {'USD': 79.95, 'EUR': 79.99};
  silverpack = {
    'price': {'USD': 69.95, 'EUR': 69.95},
    'newprice': {'USD': 69.95, 'EUR': 69.95},
    'name': 'Silver Membership',
    'goname': 'Silver',
    'idem': 'silverpack',
    'points': 0,
    'autoprice': {'USD': 52.50, 'EUR': 52.33},
    'newautoprice': {'USD': 52.50, 'EUR': 52.33},
    'productid': 'S_Membership',
    'productidAs': 'S_Membership_AS',
    'wholesale': ['Hotels', 'Resorts', 'Rental Cars', 'Cruises & Activities'],
    'perks': [],
  };

  titaniumpack = {
    'price': {'USD': 149.95, 'EUR': 147.93},
    'newprice': {'USD': 149.95, 'EUR': 147.93},

    'name': 'Titanium Membership',
    'goname': 'Titanium',
    'idem': 'titaniumpack',
    'points': 150,
    'autoprice': {'USD': 75, 'EUR': 75},
    'newautoprice': {'USD': 75, 'EUR': 75},

    'productid': 'Gold_Club_Membership',
    'productidAs': 'Starter_Club',
    'bitcoinprice': 249.95,
    'wholesale': ['Hotels', 'Resorts', 'Rental Cars', 'Cruises & Activities'],
    'perks': ['Access to World Tours', 'Access to Getaways'],
    'accessto': [
      true,true,true,true,true,true,true,false,true,true,true,true,true,true,true,true,true,false,false,false,false
    ],
  };
  platinumpack = {
    'price': {'USD': 269.95, 'EUR': 263.90},
    'newprice': {'USD': 269.95, 'EUR': 263.90},

    'points': 270,
    'idem': 'platinumpack',
    'name': 'Platinum Membership',
    'goname': 'Platinum',
    'autoprice': {'USD': 135.00, 'EUR': 133.06},
    'newautoprice': {'USD': 135.00, 'EUR': 133.06},

    'productid': 'Platinum_Club_Membership',
    'productidAs': 'Travel_Club_Business_System',
    'bitcoinprice': 449.95,
    'wholesale': ['Hotels', 'Resorts', 'Rental Cars', 'Cruises & Activities'],
    'perks': ['Access to World Tours', 'Access to Getaways', '+ Buy down the rates to the lowest possible price!'],
    'accessto': [
      true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true
    ],
  };
  allpacks = [this.silverpack, this.titaniumpack, this.platinumpack];
  selectedpack;
  listofservices = [
    {'key':0,'name':"Hotel Discounts - Over 1 million Hotels Worldwide",'access':''},
    {'key':1,'name':"Rental Car Discounts",'access':''},
    {'key':2,'name':"Cruise Discounts",'access':''},
    {'key':3,'name':"Earn Loyalty points Every Time you Book Travel",'access':''},
    {'key':4,'name':"Travel Points can be used Right Away",'access':''},
    {'key':5,'name':"World Tour Access",'access':''},
    {'key':6,'name':"Earn 1 Travel Point for Every Dollar spent on Membership, then Earn 2 Points Every Month After",'access':''},
    {'key':7,'name':"Buy Down World Tours to Tier 2 Pricing",'access':''},
    {'key':8,'name':"Access to over 2,000 Getaway Destinations",'access':''},
    {'key':9,'name':"Movie Ticket Discounts",'access':'fullTravelClubInclude'},
    {'key':10,'name':"Dining Discounts",'access':'fullTravelClubInclude'},
    {'key':11,'name':"Shopping Discounts",'access':'fullTravelClubInclude'},
    {'key':12,'name':"Sporting Events Discounts",'access':'fullTravelClubInclude'},
    {'key':13,'name':"Theme Parks, Concerts, Shows, & Play Discounts",'access':'fullTravelClubInclude'},
    {'key':14,'name':"Apply more points to buy down Getaways as low as $99 for 7 Day Stays",'access':''},
    {'key':15,'name':"$200 Nationwide Dining & Online Shopping Deals",'access':'shopAndDinning'},
    {'key':16,'name':"$100 Monthly After the First Month",'access':'shopAndDinning'},
    {'key':17,'name':"$50 in Restaurant.com Monthly",'access':'restaurantDotCom'},
    {'key':18,'name':"Apply more points to Getaways as low as $0 per 7 Day Stays",'access':''},
    {'key':19,'name':"Buy Down World Tours to tier 1 Pricing",'access':''},
    {'key':20,'name':"Priorty World Tour Booking",'access':''}
  ];



   constructor(
     private cserv: CountryService,
     private route: ActivatedRoute,
     private sserv: SponsorService,
     private _location: Location,
   ) {
     this.route.params.subscribe((routeParams) => {
       this.zone = routeParams['zone'];
       this.spons = routeParams['sponsor'];
       this.fortravel = routeParams['travellevel'];
       this.fortrader = routeParams['traderlevel'];
       this.travelpack = routeParams['travelpack'];


      if (routeParams['travellevel'] || routeParams['traderlevel']) {
        this.stepone = false;
        if (routeParams['travelpack'] == "picking") {
          this.doyouwantpack = true;
        }

        if (routeParams['travellevel'] == "silverpack"
        || routeParams['travellevel'] == "titaniumpack"
        || routeParams['travellevel'] == "platinumpack") {
          if (routeParams['travellevel'] == "silverpack" ) {
              this.selectedpack = this.silverpack;
          };
          if (routeParams['travellevel'] == "titaniumpack" ) {
            this.selectedpack = this.titaniumpack;
          };
          if (routeParams['travellevel'] == "platinumpack" ) {
            this.selectedpack = this.platinumpack;
          };
          if (routeParams['checkout'] == "checkout") {
            this.partner = true;
          };
        };
        if (routeParams['travelpack'] == "yes") {
          this.istravelpack = true;
          console.log("travelpackisselected")
        }
      } else {
        this.stepone = true;
      };

       if (routeParams['zone']) {

         if (routeParams['zone'] == 'united-states') {
           this.cserv.region.subscribe(r => {
             if (r=='CA') {
              // cserv.caOn()
             }
           })
         } else {
            this.cserv.newSetCountry(this.zone)
         }
       } else {

       }
     });

   }


   topartner(selectedpack) {
     this.selectedpack = selectedpack;
     if(this.C == 'y') {
       this.enrollmentbuilder(selectedpack,'yes');
     } else {
     this.partner = true;
     window.scrollTo(0,0);

     }
   }

   enrollmentbuilder(selectedpack,cust) {
    this.sserv.sponsorInfo.subscribe(sponsinfo => {
      this.sponsor = sponsinfo.sponsorID;
      var compiledpath = this.cs4000+this.pkgtext;
      if(cust == 'yes' || this.selectpartner == false) {
        compiledpath+='99';
      } else {
        compiledpath+='1';
      };
      if(selectedpack == 'forexonly'){
        compiledpath+=this.ProductIDtext;
        compiledpath+= "Forex_Membership";
        compiledpath+=this.ProductID_AStext;
        compiledpath+= "Forex_Membership_AS";
      } else {
        if(selectedpack == 'partneronly') {
          //passing no product info
        } else {
          if (this.fortraveltrader) {
            compiledpath+=this.ProductIDtext;
            compiledpath+=selectedpack.productid;
            compiledpath+=this.ProductID_AStext;
            compiledpath+=selectedpack.productidAs;
            compiledpath+=this.ProductIDtext2;
            compiledpath+= "Forex_Membership";
            compiledpath+=this.ProductID_AStext2;
            compiledpath+= "Forex_Membership_AS";
          } else {

          compiledpath+=this.ProductIDtext;
          compiledpath+=selectedpack.productid;
          compiledpath+=this.ProductID_AStext;
          compiledpath+=selectedpack.productidAs;
          }
        }
      };
      compiledpath+=this.SponsorIDtext;
      compiledpath+=this.sponsor;
      if(this.istravelpack) {
        compiledpath+="&AddOnProdID=TravoriumTravelpack";
      }
      if(this.D == "N") {
        window.location.href = compiledpath+'&D=N';
      } else if(this.isupgrade) {
        window.location.href = compiledpath+'&upgradeid='+this.isupgrade;
      } else {
        window.location.href = compiledpath;
      };
    })
   };



   ngOnInit() {
     this.bitcoin = false;

      this.route.queryParams.subscribe(params => {
       this.D = params['D'];
       this.C = params['C'];
       this.isupgrade = params['upgradeid'];
       console.log(this.C);
     });
     this.cserv.country.subscribe(cc => {
      if (!cc) {
        return
      }
      if (['CU','CM','DM','DM','GM','NG','SL','KR',"GA","MR",'KM','SZ'].includes(cc.cc)) {
        this.nosilver = true;
        this.allpacks = [this.titaniumpack, this.platinumpack];
      } else {
        this.nosilver = false;
        this.allpacks = [this.silverpack, this.titaniumpack, this.platinumpack];
      }
      // if (cc.cc == 'CU' ||
      //   cc.cc == 'CM' ||
      //   cc.cc == 'DM' ||
      //   cc.cc == 'GM' ||
      //   cc.cc == 'NG' ||
      //   cc.cc == 'SL' ||
      //   cc.cc == 'KR' ||
      //   cc.cc == "GA" ||
      //   cc.cc == "MR" ||
      //   cc.cc == 'KM' ||
      //   cc.cc == 'SZ'
      //   ) {
      //   this.makeitfxonly = true;
      // } else {
      //   this.makeitfxonly = false;
      // }
      if (cc.cc == 'US' ||
          cc.cc == 'CA') {
        this.skipit = true;
      } else {
        this.skipit = false;
      }

      if (cc.currency) {
        this.currency = cc.currency;
      } else {
        this.currency = 'USD';
      }
    });
   }

  //  togglebit() {
  //    this.bitcoin = !this.bitcoin;
  //    console.log("bitcoinvar = " + this.bitcoin);
  //  }
  //
  //  completestepone(choice) {
  //    this.stepone = false;
  //  }
  //
  //  servicesByCountry(country): any[] {
  //    var srvs=[]
  //    for (let service of this.listofservices) {
  //      if (service.access) {
  //        if (country[service.access]) {
  //          srvs.push(service)
  //        }
  //      } else {
  //        srvs.push(service)
  //      }
  //    }
  //    return srvs
  //  }
  //
  //  servicesByCountrywithnone(): any[] {
  //   var srvs=[]
  //   for (let service of this.listofservices) {
  //     if (service.access == '') {
  //         srvs.push(service)
  //       }
  //   }
  //   return srvs
  // }

   backClicked() {
    this._location.back();
  }

  //  gofree(passedproductid) {
  //   this.sserv.sponsorInfo.subscribe(sponsinfo => {
  //    this.sponsor = sponsinfo.sponsorID
  //    var cn;
  //     var compiledpath = 'https://backoffice.travorium.com/travorium/enroll_new4/signup_form.asp?pkg=1&ProductID=PRELAUNCH&ProductID_AS=Travel_Club_Business_System&SponsorID='
  //                     +this.sponsor;
  //     if(this.D == "N") {
  //        window.location.href = compiledpath+'&D=N';
  //     } else {
  //        window.location.href = compiledpath;
  //     };
  //  })
  // };



 }
